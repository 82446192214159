<template>
    <v-container fluid class="ma-0 pa-0">
        <v-data-table :items="elencoDati" :headers="headersDati" hide-default-footer :rows-per-page="100"
            no-data-text="Nessun dato disponibile" :items-per-page="999" :search="search" height="76vh" fixed-header
            mobile-breakpoint="0">
            <template v-slot:item="{ item }">
                <tr v-on:dblclick="editDDT(item)" style="cursor: pointer;">
                    <td>{{ item.nrDocu }}</td>
                    <td class="text-xs-right">{{ item.dtDocu }}</td>
                    <td class="text-xs-right">{{ item.numRighe }}</td>
                    <td class="text-xs-right">{{ item.ragSoc }}</td>
                    <td class="text-xs-right">{{ item.nrBolla }}</td>
                    <td class="text-xs-right">{{ item.dtBolla }}</td>
                    <td class="text-xs-right">{{ item.qtaTot }}</td>
                    <td class="text-xs-right">{{ item.importoTot }}</td>
                    <td class="text-xs-right">
                        <v-btn icon small @click="editDDT(item)">
                            <v-icon small>edit</v-icon>
                        </v-btn>
                        <v-btn icon small @click="stampaDDT(item)">
                            <v-icon small>picture_as_pdf</v-icon>
                        </v-btn>
                    </td>
                </tr>
            </template>
        </v-data-table>
        <dialog-docu :dialog="dialogDocu" @chiudi="chiudiDialog" :nrDocu="nrDocuSel" :cdAnagrafica="clienteSel" />
    </v-container>
</template>

<script>
import DialogDocu from "@/components/dialog/documenti/DialogDocu.vue";

import {
    get_data,
    stampa_documento,
    get_statoDocu,
} from "@/api/api.documenti.js";

import mixinDocu from "./mixinDocu.js";

export default {
    components: {
        DialogDocu,
    },

    mixins: [mixinDocu],

    props: {
        anno: { type: Number, default: new Date().getFullYear() },
        search: { type: String, default: "" },
        mostraTutti: { type: Boolean, default: false },
    },

    data: () => ({
        nrDocuSel: "-1",
        clienteSel: "",

        elencoDati: [],
        headersDati: [],

        dialogDocu: false,
    }),

    methods: {
        async caricaDati() {
            let par = {
                anno: this.anno,
            };
            let res = await get_data("ddt/elenco", par);
            this.elencoDati = res.data;
        },


        async caricaStatoDocu(nrDocu) {
            let res = await get_statoDocu(nrDocu);
            return res.data;
        },

        async stampaDDT(item) {
            let confirm = false;
            if ((await this.caricaStatoDocu(item.nrDocu)) != "editable") {
                confirm = true;
            } else {
                confirm = await this.$confirm.open(
                    "Attenzione",
                    "Una volta stampato il documento non sarà più possibile modificarlo. Confermare la stampa?"
                );
            }

            if (!confirm) return;
            try {
                let res = await stampa_documento('ddt', item);
                this.$i2kFunctions.createBlobPdf(res.data);
            } catch (error) {
                this.$toast.alert(error);
            }
        },

        editDDT(item) {
            this.nrDocuSel = item.nrDocu.toString();
            this.clienteSel = item.codAnagrafica.toString();
            this.dialogDocu = true;
        },

        chiudiDialog(refresh) {
            this.dialogDocu = false;
            if (refresh) this.caricaDati();
        },

        nuovoDdt() {
            this.nrDocuSel = "-1";
            this.dialogDocu = true;
        },
    },

    mounted() {
        this.headersDati = this.getHeadersDdt();
    },

    created() {
        this.$parent.$on("caricaDatiDdt", this.caricaDati);
        this.$parent.$on("creaNuovoDdt", this.nuovoDdt);
    },

    beforeDestroy() {
        this.$parent.$off("caricaDatiDdt", this.caricaDati);
        this.$parent.$off("creaNuovoDdt", this.nuovoDdt);
    },
}
</script>