<template>
  <v-dialog v-model="dialog" @keydown.esc="chiudiDialog()" persistent>
    <v-card class="desktop">
      <v-card-title dense dark>
        <span style="font-size: large; margin-left: 5px"> Info Errore </span>
        <span class="ml-2 center-vertical-items">
          <v-icon v-if="info.debug" v-tooltip.bottom="'Debug'">bug_report</v-icon>
          <v-icon v-else v-tooltip.bottom="'In production'">manage_accounts</v-icon>
        </span>
        <v-spacer />
        <v-btn fab color="transparent" elevation="0" @click="chiudiDialog()">
          <v-icon> close </v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-row class="ma-0 pa-0 mb-4">
          <v-text-field outlined v-model="info.error" label="Error" hide-details />
          <v-text-field class="ml-2" outlined v-model="info.dateOccur" label="Date" style="max-width: 200px;"
            hide-details />
          <v-text-field class="ml-2" outlined v-model="info.userID" label="User ID" style="max-width: 90px;"
            hide-details />
        </v-row>
        <v-text-field outlined v-model="info.modelFunction" label="Function" />
        <v-textarea outlined label="Message" v-model="info.message" />
        <v-textarea outlined style="height: 400px" rows="13" label="StackTrace" v-model="info.stackTrace" />
      </v-card-text>
    </v-card>
    <v-card class="mobile">
      <v-card-title dense dark class="pt-0 pb-0">
        <span style="font-size: large;"> Info Errore </span>
        <span class="ml-2 center-vertical-items">
          <v-icon v-if="info.debug" v-tooltip.bottom="'Debug'">bug_report</v-icon>
          <v-icon v-else v-tooltip.bottom="'In production'">manage_accounts</v-icon>
        </span>
        <v-spacer />
        <v-btn fab color="transparent" elevation="0" @click="chiudiDialog()">
          <v-icon> close </v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="ma-0 pa-1">
        <v-text-field outlined v-model="info.error" label="Error" hide-details />
        <v-row class="ma-0 pa-0 mb-4 mt-2">
          <v-text-field outlined v-model="info.dateOccur" label="Date" style="max-width: 200px;" hide-details />
          <v-text-field class="ml-2" outlined v-model="info.userID" label="User ID" style="max-width: 90px;"
            hide-details />
        </v-row>
        <v-text-field outlined v-model="info.modelFunction" label="Function" />
        <v-textarea outlined label="Message" v-model="info.message" />
        <v-textarea outlined style="height: 400px" rows="13" label="StackTrace" v-model="info.stackTrace" />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: { type: Boolean, default: false },
    info: { type: Object, default: null },
  },

  data() {
    return {
      errore: {},
    };
  },

  methods: {
    chiudiDialog() {
      this.$emit("chiudiDialog");
    },
  },

  watch: {
    dialog() {
      this.errore = this.info;
    },
  },

  mounted() { },
};
</script>
