export default {
    methods: {
        // Headers tabella fatture
        getHeadersDati() {
            return [{
                text: "Numero documento",
                value: "nrDocu"
            },
            {
                text: "Data documento",
                //value: "dtDocu",
                value: "dataFormat",
                sortable: true,
                sort: (a, b) => {
                    return new Date(b) - new Date(a);
                }
            },
            {
                text: "Posizioni",
                value: "numRighe"
            },
            {
                text: "Cliente",
                value: "ragSoc"
            },
            {
                text: "Numero fattura",
                value: "nrFattura"
            },
            {
                text: "Data fattura",
                value: "dtFattura"
            },
            {
                text: "Qta",
                value: "qtaTot"
            },
            {
                text: "Importo",
                value: "importoTot",
                align: 'end',
            },
            {
                text: "IVA",
                value: "TotIva",
                align: 'end',
            },
            {
                text: "Azioni",
                value: "",
                sortable: false
            },
            ]
        },

        // Headers tabella fatture se reggruppamento = TRUE
        getHeadersDatiGruppo() {
            return [
                {
                    text: "Posizioni",
                    value: "numRighe"
                },
                {
                    text: "Cliente",
                    value: "ragSoc"
                },
                {
                    text: "Importo",
                    value: "importoTot",
                    align: 'end',
                },
                {
                    text: "IVA",
                    value: "TotIva",
                    align: 'end',
                },
                {
                    text: "Azioni",
                    value: "",
                    sortable: false
                },
            ]
        },

        // Headers tabella di expand delle fatture
        getHeadersDettaglio(raggruppa) {
            let ret = [];
            if (raggruppa) {
                ret.push({
                    text: "Documento",
                    value: "nrDocu",
                    class: "pl-0"
                }, {
                    text: "Data",
                    value: "dtDocu",
                    class: "pl-0"
                });
            }
            ret.push({
                text: "Riga",
                value: "nrRigaDocu",
                class: "pl-0 pr-0"
            },
                {
                    text: "Tipo riga",
                    value: "tpRiga",
                    class: "pl-0"
                },
                {
                    text: "Cod. Articolo",
                    value: "cdArticoloPadre",
                    class: "pl-0"
                },
                {
                    text: "Descrizione",
                    value: "descrizioneProdotto",
                    class: "pl-0"
                },
                {
                    text: "U. M.",
                    value: "unMisura"
                },
                {
                    text: "Qta ordinata",
                    value: "qtOrdinata",
                    class: "pl-0"
                },
                {
                    text: "Prezzo unitario",
                    value: "prUnitario",
                    class: "pl-0"
                },
                {
                    text: "Sconto 1",
                    value: "sconto1",
                    class: "pl-0 pr-0"
                },
                {
                    text: "Sconto 2",
                    value: "sconto2",
                    class: "pl-0 pr-1"
                },
                {
                    text: "Totale rigo",
                    value: "totRigo",
                    class: "pl-0"
                },
                {
                    text: "IVA",
                    value: "cdIva",
                    class: "pl-0"
                },
                {
                    text: "No bolla",
                    value: "noBolla",
                    class: "pl-0"
                },
                {
                    text: "No fattura",
                    value: "noFattura",
                    class: "pl-0 pr-0"
                },
            )
            return ret;
        },

        // Periodi visualizzati nella tendina dei filtri delle fatture
        getPeriodi() {
            return [
                { id: 0, testo: "" },
                { id: 1, testo: "Mese corrente" },
                { id: 2, testo: "1° Trimestre" },
                { id: 3, testo: "2° Trimestre" },
                { id: 4, testo: "3° Trimestre" },
                { id: 5, testo: "4° Trimestre" },
                { id: 6, testo: "1° Quadrimestre" },
                { id: 7, testo: "2° Quadrimestre" },
                { id: 8, testo: "3° Quadrimestre" },
                { id: 9, testo: "1° Semestre" },
                { id: 10, testo: "2° Semestre" }];
        },

        // In base al periodo selezionato imposta la data corretta da visualizzare
        applicaData(filtri, periodoSel) {
            // Data corrente
            let oggi = new Date();
            let anno = filtri.anno || new Date().getFullYear();
            const primoGiornoDelMese = (anno, mese) => new Date(Date.UTC(anno, mese, 1)).toISOString().substring(0, 10);
            const ultimoGiornoDelMese = (anno, mese) => new Date(Date.UTC(anno, mese + 1, 0)).toISOString().substring(0, 10);

            if (!periodoSel) {
                filtri.dataInizio = null;
                filtri.dataFine = null;
                return;
            }

            switch (periodoSel.id) {
                case 1:
                    filtri.dataInizio = primoGiornoDelMese(anno, oggi.getMonth());
                    filtri.dataFine = ultimoGiornoDelMese(anno, oggi.getMonth());
                    break;

                case 2:
                    filtri.dataInizio = primoGiornoDelMese(anno, 0);
                    filtri.dataFine = ultimoGiornoDelMese(anno, 2);
                    break;

                case 3:
                    filtri.dataInizio = primoGiornoDelMese(anno, 3);
                    filtri.dataFine = ultimoGiornoDelMese(anno, 5);
                    break;

                case 4:
                    filtri.dataInizio = primoGiornoDelMese(anno, 6);
                    filtri.dataFine = ultimoGiornoDelMese(anno, 8);
                    break;

                case 5:
                    filtri.dataInizio = primoGiornoDelMese(anno, 9);
                    filtri.dataFine = ultimoGiornoDelMese(anno, 11);
                    break;

                case 6:
                    filtri.dataInizio = primoGiornoDelMese(anno, 0);
                    filtri.dataFine = ultimoGiornoDelMese(anno, 3);
                    break;

                case 7:
                    filtri.dataInizio = primoGiornoDelMese(anno, 4);
                    filtri.dataFine = ultimoGiornoDelMese(anno, 7);
                    break;

                case 8:
                    filtri.dataInizio = primoGiornoDelMese(anno, 8);
                    filtri.dataFine = ultimoGiornoDelMese(anno, 11);
                    break;

                case 9:
                    filtri.dataInizio = primoGiornoDelMese(anno, 0);
                    filtri.dataFine = ultimoGiornoDelMese(anno, 5);
                    break;

                case 10:
                    filtri.dataInizio = primoGiornoDelMese(anno, 6);
                    filtri.dataFine = ultimoGiornoDelMese(anno, 11);
                    break;

                case 0:
                default:
                    filtri.dataInizio = null;
                    filtri.dataFine = null;
                    break;
            }
        },

        // Headers tabella pagina DDT
        getHeadersDdt() {
            return [
                { text: "Numero documento", value: "nrDocu" },
                {
                    text: "Data documento",
                    value: "dataFormat",
                    sortable: true,
                    sort: (a, b) => {
                        return new Date(b) - new Date(a);
                    }
                },
                { text: "Posizioni", value: "numRighe" },
                { text: "Cliente", value: "ragSoc" },
                { text: "Numero bolla", value: "nrBolla" },
                { text: "Data bolla", value: "dtBolla" },
                { text: "Qta", value: "qtaTot" },
                { text: "Importo", value: "importoTot" },
                { text: "Azioni", value: "", sortable: false },
            ];
        },

        getTipoDocumenti() {
            return ["DDT", "Preventivi"];//, "Offerte", "Fatture proforma"
        },

        // Headers tabella pagina Preventivi
        getHeadersPrev() {
            return [
                { text: "Numero documento", value: "nrDocu" },
                { text: "Utente", value: "utente" },
                {
                    text: "Data documento",
                    value: "dtCreazione",
                    sortable: true,
                    sort: (a, b) => {
                        return new Date(b) - new Date(a);
                    }
                },
                { text: "Oggetto", value: "oggetto" },
                { text: "Cliente", value: "ragSoc" },
                { text: "Importo", value: "importoTot" },
                { text: "Azioni", value: "", sortable: false },
            ];
        },

        // Headers tabella di dettaglio preventivo
        getHeadersDettaglioPrev() {
            return [
                { text: "Riga", value: "nrRiga" },
                { text: "Titolo", value: "titolo" },
                { text: "Descrizione", value: "descrizione" },
                { text: "Importo", value: "importo" },
                { text: "Note", value: "note" },
                { text: "Azioni", value: "azioni" },
            ];
        },

        // Elenco degli stati che può avere un preventivo e la relativa icona
        getStatusMap() {
            return {
                0: { key: "0", icon: "create", tooltip: "Preventivo creato", color: "blue" },
                1: { key: "1", icon: "save", tooltip: "Preventivo salvato", color: "indigo" },
                2: { key: "2", icon: "print", tooltip: "Preventivo stampato", color: "orange" },
                3: { key: "3", icon: "schedule_send", tooltip: "Inviato al cliente", color: "cyan" },
                4: { key: "4", icon: "thumb_up_alt", tooltip: "Confermato", color: "green" },
                5: { key: "5", icon: "highlight_off", tooltip: "Annullato", color: "red" },
                6: { key: "6", icon: "delete", tooltip: "Eliminato", color: "grey" },
                7: { key: "7", icon: "auto_delete", tooltip: "Obsoleto", color: "brown" },
            };
        },
    }
}