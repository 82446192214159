<template src="./riepilogoOre.html"></template>

<script>
import { get_utenti, get_clienti } from "@/api/api.calendar.js";

import TabellaRiepilogo from "./TabellaRiepilogo.vue";

import { get_oreClienti } from "@/api/api.clienti.js";
import mixinr from "./mixinRiepilogo.js";
import storage from "@/services/storage.js";

export default {

  components: {
    TabellaRiepilogo,
  },

  mixins: [mixinr],

  data: () => ({
    search: "",
    filtri: {},

    calendarDataDa: false,
    calendarDataA: false,
    mcalendarDataDa: false,
    mcalendarDataA: false,

    elencoClienti: [],
    elencoOperatori: [],
    destinazioni: [],
    tipiOre: [],

    elencoDati: [],
    totale: {},

    includiOreUfficio: false,
    raggruppaClienti: false,

    // Dialog di dettaglio che si apre sul click di una riga ragguppata
    dettaglioDati: [],
    dialogInfo: false,
    dettaglioTotale: {},
  }),

  methods: {
    async caricaDati() {
      this.caricaOperatori();
      this.caricaClienti();
      this.caricaOreClienti();
    },

    async caricaOperatori() {
      let res = await get_utenti();
      this.elencoOperatori = res.data;
    },

    async caricaClienti() {
      let res = await get_clienti();
      this.elencoClienti = res.data;
    },

    async caricaOreClienti() {
      if (
        this.filtri.dataDa == null ||
        this.filtri.dataDa == "" ||
        this.filtri.dataA == null ||
        this.filtri.dataA == ""
      ) {
        return;
      }
      let res = await get_oreClienti(
        this.raggruppaClienti ? "1" : "0",
        this.filtri
      );
      this.elencoDati = res.data.elenco;
      this.totale = res.data.totale;
    },

    refresh() {
      this.caricaDati();
    },

    dateDefault() {
      let date = new Date();
      this.filtri.dataA = date.toISOString().substring(0, 10);
      this.filtri.dataDa = new Date(date.getFullYear(), date.getMonth(), 1 + 1)
        .toISOString()
        .substring(0, 10);
    },

    async dettaglioCliente(item) {
      this.dialogInfo = true;
      let filtriL = {};
      Object.assign(filtriL, this.filtri);
      // Sul click di una riga delle ore in ufficio senza cliente va in errore il dettaglio perciò esco: 
      // sarebbe da modificare la funzione per il recupero dati e gestire il cliente vuoto
      try {
        filtriL.cliente = this.elencoClienti.filter(
          (c) => c.ragSoc == item.cliente
        )[0].codAnagrafica;
      }
      catch (exception) {
        this.dialogInfo = false;
        return;
      }
      filtriL.destinazione = item.destinazione;

      let res = await get_oreClienti("0", filtriL);
      this.dettaglioDati = res.data.elenco;
      this.dettaglioTotale = res.data.totale;
    },

    getFullscreen() {
      return this.$functions.isMobile()
    },

  },

  mounted() {
    this.filtri = this.getFiltri();
    this.destinazioni = this.getDestinazioni();
    this.tipiOre = this.getTipiOre();

    this.dateDefault();
    this.caricaDati();
  },

  created() {
    if (this.role != "admin") this.$router.push({ path: "/" });
  },

  computed: {
    role() {
      return storage.getRole();
    },
  },

  watch: {
    "filtri.dataDa": function (newVal, oldVal) {
      if (
        newVal != null &&
        newVal != "" &&
        this.filtri.dataA != null &&
        this.filtri.dataA != "" &&
        newVal > this.filtri.dataA
      ) {
        this.filtri.dataDa = oldVal;
        this.$toast.alert("Data non valida");
      }
      this.filtri.dataDaIT = this.$i2kFunctions.formatISODate(
        this.filtri.dataDa
      );
    },

    "filtri.dataA": function (newVal, oldVal) {
      if (
        newVal != null &&
        newVal != "" &&
        this.filtri.dataDa != null &&
        this.filtri.dataDa != "" &&
        newVal < this.filtri.dataDa
      ) {
        this.filtri.dataA = oldVal;
        this.$toast.alert("Data non valida");
      }
      this.filtri.dataAIT = this.$i2kFunctions.formatISODate(this.filtri.dataA);
    },

  },
};
</script>

<style scoped>
tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.15);
}

.totale {
  font-weight: bold;
  font-size: 1.1em;
  padding: 0;
  vertical-align: middle;
  display: flex;
  align-items: center;
}
</style>