<template src="./dialogPreventivo.html"></template>

<script>
import { get_clienti } from "@/api/api.calendar.js";
import {
    get_infoDocu,
    salva_documento,
    get_dettaglioDocu,
    esporta_documento,
    del_documento
} from "@/api/api.documenti.js";
import { get_infoCliente } from "@/api/api.anagrafica.js";

import mixinDocu from "@/views/documenti/mixinDocu.js";

export default {
    props: {
        dialog: { type: Boolean, default: false },
        nrDocu: { type: String, default: "-1" },
        cdAnagrafica: { type: String, default: "" }
    },

    mixins: [mixinDocu],

    data: () => ({
        activeTab: 0,

        keyCli: 0,
        elencoClienti: [],
        datiTestata: {
            dtDocu: "",
            stato: "0",
        },

        calendarData: false,

        headersDettaglio: [],
        datiDettaglio: [],
        statusMap: {},

        expanded: [],
        editItem: {},

        valid: true,
        required: [(v) => !!v || "Campo obbligatorio."],

        dialogStato: false,
        nuovoStato: "",
    }),

    methods: {
        //#region Caricamento dati

        async caricaClienti() {
            let res = await get_clienti();
            this.elencoClienti = res.data;
        },

        async caricaDati() {
            let res = await get_infoDocu("preventivo", this.nrDocu);
            this.datiTestata = res.data;

            if (this.nrDocu != "-1") {
                this.caricaDatiCliente();
                this.caricaDettaglio();
            }
            this.normalizzaData();
        },

        async caricaDettaglio() {
            let res = await get_dettaglioDocu("preventivo", this.nrDocu);
            this.datiDettaglio = res.data;

            this.aggiornaTotale();
        },

        //#endregion


        //#region Info cliente

        async caricaDatiCliente() {
            if (this.cdAnagrafica == "") return;
            let res = await get_infoCliente(this.cdAnagrafica);

            this.datiTestata.cliente = res.data;
            this.keyCli += 1;
        },

        aggiornaCli() {
            this.keyCli += 1;
        },

        checkCliente(datiTestata) {
            if (
                datiTestata.cliente == {} ||
                datiTestata.cliente == null ||
                datiTestata.cliente.codAnagrafica == undefined ||
                datiTestata.cliente.codAnagrafica == ""
            ) {
                return false;
            }
            return true;
        },

        //#endregion


        //#region Operazioni documento

        async salva() {
            if (this.editItem.salvato == false && this.editItem.modificato) {
                this.$toast.alert("Attenzione. Prima di continuare salvare la posizione attuale");
                return;
            }
            this.datiTestata.cdContatto = this.datiTestata.cliente.codAnagrafica;
            let par = {
                Testata: this.datiTestata,
                Posizioni: this.datiDettaglio,
            };
            let res = await salva_documento("preventivo", par);
            if (res.data != "OK") this.$toast.alert("Errore. " + res.data);
            else this.$toast.info("Dati documento salvati");
        },

        async eliminaPrev() {
            let docu = this.nrDocu;
            if (this.nrDocu == "-1") {
                docu = this.datiTestata.id;
            }

            let confirm = await this.$confirm.open(
                "Elimina",
                "Eliminare il documento corrente?"
            );

            if (!confirm) return;
            let res = await del_documento("preventivo", docu);
            if (res.data == "OK") {
                this.$toast.info("Preventivo eliminato");
                this.chiudi(true);
            }
        },

        async esportaDocumento(tipo) {
            if (!this.checkNumero(this.datiTestata)) return;
            if (!this.checkCliente(this.datiTestata)) {
                this.$toast.alert("Nessun cliente selezionato");
                return;
            }
            if (
                !this.editItem.salvato && this.editItem.modificato) {
                this.$toast.alert("Attenzione. Prima di continuare salvare la posizione attuale");
                return;
            }
            await this.salva();
            this.$toast.info("Esportazione in corso...");
            if (this.datiDettaglio.length == 0) {
                let confirm = await this.$confirm.open(
                    "Attenzione",
                    "Il documento non contiene alcuna riga. Proseguire con l'esportazione?"
                );
                if (!confirm) return;
            }

            let docu = this.nrDocu;
            if (this.nrDocu == "-1") {
                docu = this.datiTestata.id;
            }
            let res = await esporta_documento(tipo, docu);
            if (tipo == "pdf") this.$i2kFunctions.createBlobPdf(res.data);
            else if (tipo == "word") {
                let url = window.URL.createObjectURL(res.data);

                let a = document.createElement("a");
                a.href = url;
                a.download = "preventivo.doc";

                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);
            }
        },

        cambiaStato() {
            this.nuovoStato = this.datiTestata.stato;
            this.dialogStato = true;
        },

        salvaStato(value) {
            if (!value || value == "") return;
            this.chiudiStato();
            this.datiTestata.stato = value;
            this.salva();
        },

        chiudiStato() {
            this.dialogStato = false;
        },

        //#endregion


        checkNumero(datiTestata) {
            if (datiTestata.nrDocu == 0) {
                this.$toast.alert("Nessun documento selezionato");
                return false;
            }
            return true;
        },

        expandItem(item) {
            this.datiDettaglio.forEach((el) => {
                if (el != item) el.expanded = false;
            });
            if (
                this.statoDocu != "confirmed" &&
                item.expanded &&
                !this.editItem.salvato && this.editItem.modificato
            ) {
                this.$toast.alert("Attenzione. Prima di continuare salvare la posizione attuale");
                return;
            }
            item.expanded = !item.expanded;
            this.expanded = [];
            if (item.expanded) {
                this.expanded.push(item);
                this.editItem = item;
                this.editItem.salvato = false;
            } else this.editItem = {};
        },


        //#region Operazioni posizione

        aggiungiPosizione() {
            if (!this.checkNumero(this.datiTestata)) return;
            if (!this.checkCliente(this.datiTestata)) {
                this.$toast.alert("Nessun cliente selezionato");
                return;
            }
            if (
                this.statoDocu != "confirmed" &&
                this.editItem.expanded &&
                !this.editItem.salvato && this.editItem.modificato
            ) {
                this.$toast.alert("Attenzione. Prima di continuare salvare la posizione attuale");
                return;
            }

            this.activeTab = 0;

            //aggiungo il primo elemento nella lista
            let elem = {};
            let riga = 0;
            if (this.datiDettaglio.length > 0) {
                //recupero il numero massimo delle posizioni presenti nella lista
                riga = Math.max(...this.datiDettaglio.map((o) => o.nrRiga));
            }
            //dati di default
            elem = {
                nrRiga: riga + 1,
                importo: 0,
                expanded: true,
            };

            this.datiDettaglio.push(elem);
            this.expanded = [];
            this.expanded.push(elem);
            this.editItem = elem;
        },

        salvaPosizione() {
            if (!this.controllaDati()) return;
            this.editItem.salvato = true;
            this.editItem.expanded = false;
            this.expanded = [];
            this.editItem = {};

            this.aggiornaTotale();
        },

        controllaDati() {
            if ((this.editItem.titolo.trim() == "" || this.editItem.titolo == null) && (this.editItem.descrizione.trim() == "" || this.editItem.descrizione == null)) {
                this.$toast.alert("Attenzione. Compilare almeno uno dei due campi 'Titolo' oppure 'Descrizione'");
                return false;
            }
            return true;
        },

        async eliminaPosizione() {
            let confirm = await this.$confirm.open(
                "Elimina",
                "La posizione corrente verrà eliminata definitivamente. Continuare?"
            );

            if (!confirm) return;
            this.datiDettaglio = this.datiDettaglio.filter(
                (x) => x.nrRiga != this.editItem.nrRiga
            );
            this.expanded = [];
            this.editItem = {};
        },

        //#endregion


        //#region Operazioni varie

        aggiornaTotale() {
            let tot = 0;
            this.datiDettaglio.forEach((el) => {
                tot += Number(el.importo);
            });
            this.datiTestata.importoTot = Number(tot).toFixed(2);
            if (!this.datiTestata.ivaInclusaInPrezzo) {
                this.datiTestata.iva = (this.datiTestata.importoTot * 0.22).toFixed(2)
            }
        },

        // Mi serve per convertire la data dal formato ISO al formato dd/mm/yyyy
        normalizzaData() {
            if (
                this.datiTestata.dtDocu &&
                this.datiTestata.dtDocu.split("-").length < 3
            )
                this.datiTestata.dtDocu = this.$i2kFunctions.formatITDateToISO(
                    this.datiTestata.dtDocu.substring(0, 10)
                );
            if (this.datiTestata.dtDocu.length > 10)
                this.datiTestata.dtDocu = this.datiTestata.dtDocu.substring(0, 10);
            this.datiTestata.dtDocuIT = this.$i2kFunctions.formatISODate(this.datiTestata.dtDocu);
        },

        disabilita(elem) {
            //se true disabilita o rende readonly il controllo
            switch (elem) {
                case "salva":
                    return (
                        this.statoDocu == "confirmed" ||
                        (this.editItem != {} &&
                            this.editItem != null &&
                            this.editItem.nrRiga != undefined)
                    );
                case "annulla":
                    return (
                        this.editItem != {} &&
                        this.editItem != null &&
                        this.editItem.nrRiga != undefined
                    );
                case "posizione":
                    return this.stato == "confirmed";

                default:
                    break;
            }
        },

        reset() {
            this.datiTestata = {};
            this.datiDettaglio = [];
            this.editItem = {};
            this.expanded = [];
        },

        //#endregion


        //#region Operazioni DIALOG
        chiudi(aggiorna) {
            this.reset();
            this.$emit("chiudi", aggiorna);
        },

        getFullscreen() {
            return this.$functions.isMobile()
        },

        //#endregion
    },

    beforeMount() {
        this.statusMap = this.getStatusMap();
    },

    mounted() {
        this.datiTestata.dtDocu = new Date().toISOString().substring(0, 10);
        this.caricaClienti();
    },

    created() {
        this.headersDettaglio = this.getHeadersDettaglioPrev();
    },

    watch: {
        "datiTestata.dtDocu": function (newVal, oldVal) {
            if (newVal == null || newVal == "") {
                this.datiTestata.dtDocuIT = "";
                return;
            }
            let dtTemp = this.$i2kFunctions.formatISODate(newVal);
            if (dtTemp && typeof dtTemp !== "undefined")
                this.datiTestata.dtDocuIT = dtTemp;
            else this.datiTestata.dtDocuIT = newVal;
        },

        dialog(newVal) {
            if (newVal) this.caricaDati();
        },

        "datiTestata.ivaInclusaInPrezzo": function (oldVal, newVal) {
            if (!newVal) this.datiTestata.iva = 0;
            else this.aggiornaTotale();
        },

        "datiTestata.timbro": function (newVal) {
            if (!newVal && !this.datiTestata.firma) this.datiTestata.firma = true;
        },

        "datiTestata.firma": function (newVal) {
            if (!newVal && this.datiTestata.timbro) this.datiTestata.timbro = false;
        },
    }


}
</script>

<style scoped>
.sticky-header {
    position: sticky;
    top: 0;
    z-index: 10;
}
</style>