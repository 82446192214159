<template>
    <v-container fluid class="ma-0 pa-0">
        <v-data-table :items="elencoDati" :headers="headersDati" hide-default-footer :rows-per-page="999"
            no-data-text="Nessun dato disponibile" :items-per-page="999" :search="search" :single-expand="true"
            item-key="nrDocu" :expanded.sync="expanded" :height="altezzaTabella" dense fixed-header
            :mobile-breakpoint="0">
            <template v-slot:item="{ item }">
                <tr>
                    <td v-if="!filtri.raggruppaClienti">{{ item.nrDocu }}</td>
                    <td class="text-xs-right" v-if="!filtri.raggruppaClienti">{{ item.dtDocu }}</td>
                    <td class="text-xs-right">{{ item.numRighe }}</td>
                    <td class="text-xs-right">{{ item.ragSoc }}</td>
                    <td class="text-xs-right" v-if="!filtri.raggruppaClienti"> {{ item.nrFattura }}</td>
                    <td class="text-xs-right" v-if="!filtri.raggruppaClienti">{{ item.dtFattura }}</td>
                    <td class="text-xs-right" v-if="!filtri.raggruppaClienti">{{ item.qtaTot }}</td>
                    <td class="text-right pl-1 pr-0">&euro; {{
                        $functions.numberWithCommas(item.importoTot.toFixed(2)) }}
                    </td>
                    <td class="text-right pl-1 pr-0">&euro; {{
                        $functions.numberWithCommas(item.totIva.toFixed(2)) }}
                    </td>
                    <td class="text-xs-right">
                        <v-btn icon small @click="caricaInfo(item)">
                            <v-icon v-if="item.expanded">expand_less</v-icon>
                            <v-icon v-else>expand_more</v-icon>
                        </v-btn>
                    </td>
                </tr>
            </template>

            <template v-slot:expanded-item="{ headers }">
                <td :colspan="headers.length">
                    <v-card class="mb-2 elevation-2 mr-4 ml-4" outlined>
                        <v-data-table :headers="headersDettaglio" :items="dettaglioDocu" class="ma-2"
                            hide-default-footer :rows-per-page="999" :items-per-page="999" dense
                            no-data-text="Nessun dato disponibile">
                            <template v-slot:item="{ item }">
                                <tr v-if="item.tpRiga == 'DESCRITTIVA'">
                                    <td class="pl-0" v-if="filtri.raggruppaClienti">{{ item.nrDocu }}</td>
                                    <td class="pl-0" v-if="filtri.raggruppaClienti">{{
                                        $i2kFunctions.formatISODate(item.dtDocu) }}</td>
                                    <td class="pr-0 pl-0">{{ item.nrRigaDocu }}</td>
                                    <td class="text-xs-right pl-0">{{ item.tpRiga }}</td>
                                    <td></td>
                                    <td :colspan="headersDettaglio.length - 2" class="pl-0">
                                        {{ item.descrizioneAggiuntiva }}</td>
                                </tr>
                                <tr v-else>
                                    <td class="pl-0" v-if="filtri.raggruppaClienti">{{ item.nrDocu }}</td>
                                    <td class="pl-0" v-if="filtri.raggruppaClienti">{{
                                        $i2kFunctions.formatISODate(item.dtDocu) }}</td>
                                    <td class="pr-0 pl-0">{{ item.nrRigaDocu }}</td>
                                    <td class="text-xs-right pl-0">{{ item.tpRiga }}</td>
                                    <td class="text-xs-right pl-0">{{ item.cdArticoloPadre }}</td>
                                    <td class="text-xs-right pl-0">{{ item.descrizioneProdotto }}
                                        <v-divider />
                                        {{ item.descrizioneAggiuntiva }}
                                    </td>
                                    <td class="text-xs-right"> {{ item.unMisura }}</td>
                                    <td class="text-xs-right pl-0">{{ item.qtOrdinata }}</td>
                                    <td class="text-xs-right pl-0"> &euro;{{
                                        $functions.numberWithCommas(item.prUnitario.toFixed(2)) }}
                                    </td>
                                    <td class="text-xs-right pl-0 pr-0">{{ item.sconto1 }}</td>
                                    <td class="text-xs-right pl-0 pr-0">{{ item.sconto2 }}</td>
                                    <td class="text-xs-right pl-1 pr-1"> &euro;{{ getTotRigo(item) }}</td>
                                    <td class="text-xs-right pl-0">{{ item.cdIva }}</td>
                                    <td class="text-xs-right pl-0 pr-0">
                                        <v-icon v-if="!item.noBolla" color="red">close</v-icon>
                                        <v-icon v-else color="green">done</v-icon>
                                    </td>
                                    <td class="text-xs-right pl-0 pr-0">
                                        <v-icon v-if="!item.noFattura" color="red">close</v-icon>
                                        <v-icon v-else color="green">done</v-icon>
                                    </td>
                                </tr>
                            </template>
                        </v-data-table>
                    </v-card>
                </td>
            </template>

            <template v-slot:footer>
                <v-divider />
                <v-row class="ma-0 pa-0 desktop">
                    <v-col cols="3">
                        <span>
                            Importo totale:
                            <strong>
                                &euro;{{ importoTotale }}
                            </strong>
                        </span>
                    </v-col>
                    <v-col cols="3">
                        <span>
                            IVA totale:
                            <strong>
                                &euro;{{ ivaTotale }}
                            </strong>
                        </span>
                    </v-col>
                    <v-spacer />
                    <v-col cols="2" style="text-align: end !important;">
                        <v-btn v-if="grafico > 0" class="elevation-2" color="tertiary" @click="mostraGrafico()">
                            <v-icon color="primary">insights</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>

                <v-row class="ma-0 pa-0 mobile">
                    <v-row style="display: flex;" class="ma-0 pa-0">

                        <v-col cols="5" class="pb-0 pt-2">
                            <span>
                                Importo totale:
                                <strong>
                                    &euro;{{ importoTotale }}
                                </strong>
                            </span>
                        </v-col>
                        <v-col cols="4" class="pb-0 pt-2">
                            <span>
                                IVA totale:
                                <strong>
                                    &euro;{{ ivaTotale }}
                                </strong>
                            </span>
                        </v-col>
                        <v-spacer />
                        <v-col cols="3" class="pb-0 pt-2" style="text-align: end !important;">
                            <v-btn v-if="grafico > 0" class="elevation-2" color="tertiary" @click="mostraGrafico()">
                                <v-icon color="primary">insights</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-row>
            </template>

        </v-data-table>
    </v-container>
</template>

<script>
import { get_data, get_dettaglioDocu } from "@/api/api.documenti.js";

import mixinDocu from "./mixinDocu.js";

export default {
    name: "TabellaFatture",
    mixins: [mixinDocu],

    props: {
        filtri: { type: Object, default: null },
        search: { type: String, default: "" },
        aggiornaDati: { type: Number, default: 0 },
        grafico: { type: Boolean, default: true },
        altezzaTabella: { type: String, default: "69vh" },
        elencoClienti: { type: Array, default: () => [] },
    },

    data: () => ({
        elencoDati: [],
        headersDati: [],
        dettaglioDocu: [],
        headersDettaglio: [],

        importoTotale: 0,
        ivaTotale: 0,

        dialogGrafico: false,

        expanded: [],
    }),

    methods: {
        async caricaDati() {
            if (!this.filtri.raggruppaClienti)
                this.headersDati = this.getHeadersDati();
            else
                this.headersDati = this.getHeadersDatiGruppo();

            this.headersDettaglio = this.getHeadersDettaglio(this.filtri.raggruppaClienti);

            let res = await get_data("fatture/elenco", this.filtri);
            this.elencoDati = res.data;

            this.importoTotale = 0;
            this.ivaTotale = 0;
            this.elencoDati.forEach((el) => {
                this.importoTotale += el.importoTot;
                this.ivaTotale += el.totIva;
            });
            this.importoTotale = this.$functions.numberWithCommas(this.importoTotale.toFixed(2));
            this.ivaTotale = this.$functions.numberWithCommas(this.ivaTotale.toFixed(2));
        },

        async caricaInfo(item) {
            this.elencoDati.forEach((el) => {
                if (el != item) el.expanded = false;
            });
            item.expanded = !item.expanded;

            this.expanded = [];
            if (item.expanded) {
                this.expanded.push(item);
                let res = {};
                let mese = this.filtri.mese + 1;
                if (!this.filtri.raggruppaClienti)
                    res = await get_dettaglioDocu("fatture", item.nrDocu + "/" + mese);
                else
                    res = await get_dettaglioDocu("fatture/gruppo/" + item.codAnagrafica, this.filtri.anno + "/" + mese)
                this.dettaglioDocu = res.data;
            } else this.dettaglioDocu = [];
        },

        getTotRigo(item) {
            return this.$functions.numberWithCommas(
                (item.prUnitario * item.qtOrdinata).toFixed(2)
            );
        },

        mostraGrafico() {
            this.$emit("mostraGrafico");
        },

    },

    created() {
        this.headersDati = this.getHeadersDati();
    },

    mounted() {
        this.caricaDati();
    },

    watch: {
        aggiornaDati() {
            this.caricaDati();
        }
    }
}
</script>