import request from '@/services/interceptor.js'

//#region DDT
export const get_infoDocu = (tipologia, par) => {
    return request({
        url: '/documenti/' + tipologia + '/info/' + par,
        method: 'get',
        loadingBar: true
    })
};

export const get_data = (par, data) => {
    return request({
        url: '/documenti/' + par,
        method: 'post',
        data: data,
        loadingBar: true
    })
};


export const get_dettaglioDocu = (type, par) => {
    return request({
        url: '/documenti/' + type + "/dettaglio/" + par,
        method: 'get',
        loadingBar: true
    })
};

//ritorna lo stato di modifica del documento (editable, confirmed, error)
export const get_statoDocu = (par) => {
    return request({
        url: '/documenti/ddt/stato/' + par,
        method: 'get',
        loadingBar: true
    })
};

export const salva_documento = (tipologia, data) => {
    return request({
        url: '/documenti/' + tipologia + '/salva',
        method: 'post',
        data: data,
        loadingBar: true
    })
};

export const del_documento = (tipo, par) => {
    return request({
        url: '/documenti/' + tipo + '/delete/' + par,
        method: 'delete',
        loadingBar: true
    })
};


export const stampa_documento = (tipo, data) => {
    return request({
        url: '/documenti/' + tipo + '/stampa',
        method: 'post',
        data: data,
        responseType: 'blob',
        loadingBar: true
    })
};

export const esporta_documento = (tipo, nrDocu) => {
    return request({
        url: '/documenti/esporta/' + tipo + '/' + nrDocu,
        method: 'get',
        responseType: 'blob',
        loadingBar: true
    })
};

export const download_modello = (tipo) => {
    return request({
        url: '/documenti/download/modello/' + tipo,
        method: 'get',
        responseType: 'blob',
        loadingBar: true
    })
};

//#endregion

//#region FATTURE


export const get_dati_grafico = (data) => {
    return request({
        url: '/documenti/fatture/grafico',
        method: 'post',
        data: data,
        loadingBar: true
    })
};

export const get_dati_grafico_tot = (data) => {
    return request({
        url: '/documenti/fatture/totale/grafico',
        method: 'post',
        data: data,
        loadingBar: true
    })
};

export const get_dati_grafico_mese = (data) => {
    return request({
        url: '/documenti/fatture/mese/grafico',
        method: 'post',
        data: data,
        loadingBar: true
    })
};
//#endregion