<template src="./preferences.html"></template>


<script>
import { version } from "../../package";
import storage from "@/services/storage.js";
import mixinDocu from "./documenti/mixinDocu";

export default {
  mixins: [mixinDocu],

  data: () => ({
    preferences: {
      darkTheme: false,
    },

    menuSel: "",
    documentoSel: "",
  }),

  methods: {
    changeTheme() {
      storage.setPreferences(this.preferences);
      if (this.preferences.darkTheme) this.$vuetify.theme.dark = true;
      else this.$vuetify.theme.dark = false;
    },

    setMenuDefault() {
      storage.setMenuDefault(this.menuSel);
    },


    setDocDefault() {
      if (this.documentoSel)
        storage.setDocDefault(this.documentoSel);
    },
  },

  mounted() {
    if (this.getPreferences) this.preferences = this.getPreferences;
    if (this.getMenuDefault) this.menuSel = this.getMenuDefault;
    if (this.getDocDefault) this.documentoSel = this.getDocDefault;
  },

  computed: {
    getPreferences() {
      return JSON.parse(storage.getPreferences());
    },

    version() {
      return version;
    },

    getMenuDefault() {
      return JSON.parse(storage.getMenuDefault());
    },

    elencoMenu() {
      return this.$store.getters["user/getMenu"];
    },

    getDocDefault() {
      return storage.getDocDefault();
    },

    elencoDocumenti() {
      return this.getTipoDocumenti();
    },

    mostraPreferenzeDocumenti() {
      let val = this.elencoMenu.filter((el) => el.menuName == "Documenti").length;
      return val >= 1;
    }
  },
};
</script>