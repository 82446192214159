export default {
    methods: {
        // Grafico misto a barre + a linee
        getOptionsMixedChart() {
            return {
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    labels: {
                    }
                },
                animation: {
                    duration: 0,
                },
                plugins: {
                    datalabels: {
                        display: (context) => {
                            // mostra le etichette solo per il dataset del grafico a barre
                            return context.dataset.type === "bar";
                        },
                        color: "#D7D7D7",
                    },

                },
                tooltips: {
                    enabled: true,
                    callbacks: {
                        label: (tooltipItem, data) => {
                            const dataset = data.datasets[tooltipItem.datasetIndex];
                            const pointLabels = dataset.pointLabels;
                            const value = pointLabels
                                ? pointLabels[tooltipItem.index]
                                : tooltipItem.value;
                            return `${value}`;
                        }
                    }
                },
                scales: {
                    yAxes: [
                        {
                            ticks: {
                                beginAtZero: true
                            }
                        }
                    ]
                }
            };
        },

        // Grafco ciambella
        getOptionsDoughnutChart() {
            return {
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    display: false,
                },
                animation: {
                    duration: 0,
                },
                plugins: {
                    datalabels: {
                        color: "#000",
                    }
                }
            };
        },


    }

}