<template>
    <v-dialog v-model="dialog" persistent id="dialog-chart" @keydown.esc="chiudi()" :fullscreen="getFullscreen()"
        transition="dialog-bottom-transition">
        <v-card>
            <v-card-title>
                <v-autocomplete dense class="pb-1 ml-2" hide-details label="Cliente" v-model="filters.cdContatto"
                    clearable no-data-text="Nessun dato disponibile" :items="elencoClienti" item-text="ragSoc"
                    item-value="codAnagrafica" @input="caricaDati()" />

                <v-spacer />
                <v-btn icon @click="chiudi()"><v-icon>close</v-icon>
                </v-btn>
            </v-card-title>

            <v-card-text id="card-charts">
                <v-row class="ma-0 pa-0 desktop" style="height: 100%;">
                    <v-col cols="6" class="ma-0 pa-0">
                        <v-tabs height="30">
                            <v-spacer />
                            <v-tab :key="1"> Totale anni </v-tab>
                            <v-spacer />
                        </v-tabs>
                        <v-divider class="mb-2" />

                        <mixed-chart :data="dati" :options="options" :key="key" style="cursor: pointer;" />
                    </v-col>
                    <v-col cols="6" class="ma-0 pa-0" style="border-left: solid 1px rgba(0, 0, 0, .12);">
                        <tabs-grafico-torta :filters="filters" :refresh="aggiornaTot" :col1="6" />
                    </v-col>
                </v-row>

                <v-row class="ma-0 pa-0 mobile">
                    <v-row class="ma-0 pa-0">
                        <bar-chart :data="dati" :options="options" :key="key"
                            style="max-height: 300px; max-width: 86vw !important;" />
                    </v-row>
                    <v-row class="ma-0 pa-0">

                        <tabs-grafico-torta :filters="filters" :refresh="aggiornaTot" :col1="7" />
                    </v-row>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>


<script>
import { get_dati_grafico } from "@/api/api.documenti.js"

import BarChart from "@/components/chart/BarChart.js";
import MixedChart from "@/components/chart/MixedChart.js";
import TabsGraficoTorta from "./TabsGraficoTorta.vue";

import mixinCharts from "@/components/chart/mixinCharts.js";

export default {
    props: {
        dialog: { type: Boolean, default: false },
        filtri: { type: Object, default: null },
        elencoClienti: { type: Array, default: () => [] }
    },

    components: {
        BarChart, MixedChart,
        TabsGraficoTorta
    },

    mixins: [mixinCharts],

    data: () => ({
        filters: {},
        dati: {},
        options: {},

        loaded: false,
        key: 0,
        aggiornaTot: 0,

    }),

    methods: {
        chiudi() {
            this.$emit("chiudi");
        },

        async caricaDati() {
            this.dati = {};
            let res = await get_dati_grafico(this.filters);
            this.dati = res.data;
            this.key += 1;
            this.caricaDatiTot();
        },

        async caricaDatiTot() {
            this.aggiornaTot += 1;
        },

        handleChartClick(event, elements) {
            if (elements[0] == undefined) return;
            if (event.srcElement.id != "bar-chart") return;
            this.filters.anno = this.dati.labels[elements[0]._index];
            this.caricaDatiTot();
        },

        getFullscreen() {
            return this.$functions.isMobile()
        }
    },

    mounted() {
        this.options = this.getOptionsMixedChart();
        this.options.onClick = this.handleChartClick;
    },

    watch: {
        dialog() {
            if (this.dialog) {
                Object.assign(this.filters, this.filtri);
                this.filters.anno = this.filtri.anno;
                this.filters.cdContatto = this.filtri.cdContatto;
                this.caricaDati();
            }
        },
    },
}

</script>
