<template src="./anagrafica.html"></template>

<script>
import {
  get_utenti,
  get_clienti,
  get_anagrafica,
} from "@/api/api.anagrafica.js";

import ModificaUtente from "@/components/dialog/anagrafica/ModificaUtente.vue";
import storage from "../../services/storage";
import mixinAnagrafica from "./mixinAnagrafica.js";
import NuovoMenu from "@/components/dialog/anagrafica/NuovoMenu.vue";

export default {
  components: {
    ModificaUtente,
    NuovoMenu,
  },

  mixins: [mixinAnagrafica],

  data: () => ({
    elencoDati: [],
    headersDati: [],

    activeTab: 0,
    dialogModifica: false,
    editItem: {},
    search: "",

    mostraPrezzi: false,

    dialogMenu: false,
  }),

  methods: {
    caricaDati() {
      switch (this.activeTab) {
        case 0:
          this.caricaUtenti();
          break;
        case 1:
          this.caricaMenu();
          break;
        case 2:
          this.caricaArticoli();
          break;
        case 3:
          this.caricaClienti();
          break;
        default:
          break;
      }
    },

    async caricaClienti() {
      this.headersDati = this.getHeadersClienti();
      let res = await get_clienti();
      this.elencoDati = res.data;
    },

    async caricaUtenti() {
      this.headersDati = this.getHeadersUtenti();
      let res = await get_utenti();
      this.elencoDati = res.data;
    },

    async caricaMenu() {
      this.headersDati = this.getHeadersMenu();
      let res = await get_anagrafica("menu");
      this.elencoDati = res.data;
    },

    async caricaArticoli() {
      this.headersDati = this.getHeadersArticoli();
      let res = await get_anagrafica("articoli");
      this.elencoDati = res.data;
    },

    refresh() {
      this.caricaDati();
    },

    /* async */ modifica(item) {
      /* let res = await get_anagrafica("menu");
      this.elencoMenu = res.data; */

      this.editItem = item;
      this.dialogModifica = true;
    },

    chiudiDialog() {
      this.dialogModifica = false;
    },

    add() {
      switch (this.activeTab) {
        case 0:
          //utenti
          break;
        case 1:
          //menu
          //this.apriDialogMenu();
          break;
        default:
          break;
      }
    },

    visPrezzi() {
      if (this.mostraPrezzi) {
        switch (this.activeTab) {
          case 2:
            this.headersDati.push({
              text: "Prezzo1",
              value: "prezzo1",
            });
            this.headersDati.push({
              text: "CodIva",
              value: "codIva",
            });
            break;
          case 3:
            this.headersDati.push({
              text: "Importo",
              value: "importo",
            });
            break;
        }
      } else {
        this.headersDati.splice(this.headersDati.length - 1, 1);
        switch (this.activeTab) {
          case 2:
            this.headersDati.splice(this.headersDati.length - 1, 1);
            break;
        }
      }
    },

    apriDialogMenu() {
      this.dialogMenu = true;
    },

    chiudiDialogMenu() {
      this.dialogMenu = false;
    },
  },

  mounted() {
    this.caricaDati();
  },

  computed: {
    role() {
      return storage.getRole();
    },
  },

  watch: {
    activeTab() {
      this.mostraPrezzi = false;
      this.caricaDati();
    },
  },
};
</script>

<style scoped>
tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.15);
}
</style>
