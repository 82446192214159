<template src="./documenti.html"></template>


<script>
import storage from "../../services/storage";
import TabellaDdt from "./Ddt.vue";
import TabellaPreventivi from "./Preventivi.vue";

import mixinDocu from "./mixinDocu.js";

import { download_modello } from "@/api/api.documenti.js";

export default {
  components: {
    TabellaDdt, TabellaPreventivi
  },

  mixins: [mixinDocu],

  data: () => ({
    //today: new Date().toISOString().substring(0, 10),
    search: "",

    anno: new Date().getFullYear(),
    elencoAnni: [],

    tipoDocumento: "",
    elencoDocumenti: [],

    aggiornaPrev: 0,
    nuovoDocumento: false,
    tuttiPrev: false,
  }),

  methods: {
    async caricaDati() {
      // nota: ho messo il delay perché il passaggio delle variabili al componente non avviene subito e avevo due valori diversi ed erano sfasati visualizzando i dati errati
      await this.$functions.delay(50);

      if (this.tipoDocumento == "DDT") this.caricaDatiDdt();
      else if (this.tipoDocumento == "Preventivi") this.caricaDatiPreventivi();
    },

    caricaDatiPreventivi() {
      if (this.$refs.tabellaPrev) this.$emit("caricaDatiPreventivi");
    },

    caricaDatiDdt() {
      if (this.$refs.tabellaDDT) this.$emit("caricaDatiDdt");
    },

    refresh() {
      this.caricaDati();
    },

    nuovo() {
      if (this.tipoDocumento == "DDT") this.creaNuovoDdt();
      else if (this.tipoDocumento == "Preventivi") this.creaNuovoPreventivo();
    },

    creaNuovoDdt() {
      if (this.$refs.tabellaDDT) this.$emit("creaNuovoDdt");
    },

    creaNuovoPreventivo() {
      if (this.$refs.tabellaPrev) this.$emit("creaNuovoPreventivo");
    },

    caricaAnni() {
      for (let i = 2022; i < this.anno + 1; i++)
        this.elencoAnni.push(i);
    },

    caricaDocumenti() {
      this.elencoDocumenti = this.getTipoDocumenti();
      if (this.documentoSel) this.tipoDocumento = this.documentoSel;
      else this.tipoDocumento = this.elencoDocumenti[0];
    },

    mostraPreventivi() {
      if (!this.tuttiPrev) this.tuttiPrev = true
      else this.tuttiPrev = false;
      this.caricaDati();
    },

    async scaricaModello() {
      let res = await download_modello("preventivo");
      let url = window.URL.createObjectURL(res.data);

      let a = document.createElement("a");
      a.href = url;
      a.download = "Modello_preventivo.dotx";

      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    },
  },

  mounted() {
    this.caricaDocumenti();
    this.refresh();
    this.caricaAnni();
  },

  computed: {
    role() {
      return storage.getRole();
    },

    userName() {
      return storage.getUsername();
    },

    documentoSel() {
      return storage.getDocDefault();
    },

    tipologia() {
      return this.tipoDocumento;
    },

    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    }
  },
};
</script>