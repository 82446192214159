<template>
    <v-container fluid class="ma-0 pa-0">
        <v-data-table :items="elencoDati" :headers="headersDati" hide-default-footer :rows-per-page="100"
            no-data-text="Nessun preventivo da visualizzare" :items-per-page="999" :search="search" height="76vh"
            fixed-header mobile-breakpoint="0">
            <template v-slot:item="{ item }">
                <tr v-on:dblclick="editPreventivo(item)" style="cursor: pointer;">
                    <td :class="isMobile ? 'pr-0' : ''">

                        <v-icon small v-tooltip.right="statusMap[item.stato].tooltip"
                            :color="statusMap[item.stato].color">
                            {{ statusMap[item.stato].icon }}
                        </v-icon>

                        <strong class="ml-2">{{ item.nrDocu }}</strong>
                    </td>
                    <td>{{ item.utente }}</td>
                    <td :class="isMobile ? 'text-left px-1' : 'text-xs-right'">
                        {{ $i2kFunctions.formatISODate(item.dtDocu) }}
                    </td>
                    <td :class="isMobile ? 'text-left px-1' : 'text-xs-right'">
                        {{ item.oggetto }}
                    </td>
                    <td :class="isMobile ? 'text-left px-1' : 'text-xs-right'">
                        {{ item.ragSoc }}
                    </td>
                    <td :class="isMobile ? 'text-left px-1 pr-0 text-xs-right' : 'text-xs-right'">
                        &euro; {{ item.importoTot }}
                    </td>
                    <td :class="isMobile ? 'ml-0 pl-0' : 'text-xs-right'" class="pr-0 mr-0">
                        <v-btn icon small @click="editPreventivo(item)"
                            v-tooltip.left="`Modifica preventivo ${item.nrDocu}`">
                            <v-icon>info_outline</v-icon>
                        </v-btn>
                    </td>

                </tr>
            </template>
        </v-data-table>
        <dialog-preventivo :dialog="dialogDocu" @chiudi="chiudiDialog" :nrDocu="nrDocuSel" :cdAnagrafica="clienteSel" />
    </v-container>
</template>

<script>
import DialogPreventivo from "@/components/dialog/documenti/DialogPreventivo.vue";

import {
    get_data,
    stampa_documento,
    salva_documento
} from "@/api/api.documenti.js";

import mixinDocu from "./mixinDocu.js";

export default {
    name: "TabellaPreventivi",

    components: {
        DialogPreventivo,
    },

    mixins: [mixinDocu],

    props: {
        anno: { type: Number, default: new Date().getFullYear() },
        search: { type: String, default: "" },
        mostraTutti: { type: Boolean, default: false },
    },

    data: () => ({
        nrDocuSel: "-1",
        clienteSel: "",

        elencoDati: [],
        headersDati: [],

        dialogDocu: false,

        statusMap: {},
    }),

    methods: {
        async caricaDati() {
            let par = {
                anno: this.anno,
                visualizzaTutti: this.mostraTutti,
            };
            let res = await get_data("preventivi/elenco", par);
            this.elencoDati = res.data;
        },

        nuovoPreventivo() {
            this.nrDocuSel = "-1";
            this.dialogDocu = true;
        },

        async stampaPreventivo(item) {
            try {
                let res = await stampa_documento('preventivi', item);
                this.$i2kFunctions.createBlobPdf(res.data);
            } catch (error) {
                this.$toast.alert(error);
            }
        },

        editPreventivo(item) {
            this.nrDocuSel = item.nrDocu.toString();
            this.clienteSel = item.cdContatto.toString();
            this.dialogDocu = true;
        },

        chiudiDialog(refresh) {
            this.dialogDocu = false;
            if (refresh) this.caricaDati();
        },
    },

    mounted() {
        this.headersDati = this.getHeadersPrev();
        this.statusMap = this.getStatusMap();
    },

    created() {
        this.$parent.$on("caricaDatiPreventivi", this.caricaDati);
        this.$parent.$on("creaNuovoPreventivo", this.nuovoPreventivo);
    },

    beforeDestroy() {
        this.$parent.$off("caricaDatiPreventivi", this.caricaDati);
        this.$parent.$off("creaNuovoPreventivo", this.nuovoPreventivo);
    },

    computed: {
        isMobile() {
            return this.$vuetify.breakpoint.smAndDown;
        },
    }

}
</script>