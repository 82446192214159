<template>
    <v-row class="ma-0 pa-0">
        <v-tabs v-model="activeTab" height="30">
            <v-spacer />
            <v-tab :key="1" @click="caricaDatiTot()"> Totale </v-tab>
            <v-tab :key="2" @click="caricaDatiMese()"> Mese </v-tab>
            <v-spacer />
        </v-tabs>
        <v-tabs-items :value="activeTab" touchless style="width: 100% !important;">
            <v-divider class="mb-2" />
            <v-tab-item :key="1">
                <v-row class="ma-0 pa-0">

                    <v-col :cols="col1" class="ma-0 pa-0"><!--  style="height: 400px;" -->

                        <doughnut-chart :data="datiTot" :options="optionsTot" :key="keyt" />

                    </v-col>

                    <v-col class="ma-0 pa-0" :cols="12 - col1"
                        style="font-size: 0.8em; display: flex; align-items: center; justify-content: center;">
                        <v-container fluid class="ma-0 pa-0 ml-1">
                            <v-row class="ma-0 pa-0">
                                <v-spacer />
                                <div style="font-size: 1.8em;">
                                    <strong>
                                        {{ filters.anno }}
                                        <span v-if="clienteSel()">
                                            {{ percValue }} &#x25;
                                        </span>
                                    </strong>
                                </div>
                                <v-spacer />
                            </v-row>
                            <v-spacer />

                            <v-row class="ma-0 pa-0 mb-2" v-for="(item, i) in datiTot.labels" :key="i">
                                <v-chip small :color="datiTot.datasets[0].backgroundColor[i]" />
                                {{ item }}
                                <v-spacer />
                                &euro; {{ datiTot.datasets[0].data[i].toLocaleString('it-IT', {
                                    minimumFractionDigits:
                                        2, maximumFractionDigits: 2
                                }) }}
                            </v-row>
                        </v-container>

                    </v-col>
                </v-row>
            </v-tab-item>
            <v-tab-item :key="2">
                <mixed-chart :data="datiMese" :options="optionsMese" :key="keym" style="cursor: pointer;"
                    id="month-chart" />
            </v-tab-item>
        </v-tabs-items>


        <v-dialog v-model="dialog" max-width="90%" @keydown.esc="dialog = false" :fullscreen="getFullscreen()">
            <v-card>
                <v-card-title>
                    <v-spacer />
                    <v-btn icon @click="dialog = false">
                        <v-icon>close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-divider />
                <v-card-text style="height: 75vh !important;" class="ma-0 pa-0 mb-2 mt-2">
                    <tabella-fatture :filtri="filtri" :aggiornaDati="caricaDati" :grafico="false" />
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import { get_dati_grafico_mese, get_dati_grafico_tot } from "@/api/api.documenti";

import DoughnutChart from "@/components/chart/DoughnutChart.js";
import MixedChart from "@/components/chart/MixedChart.js";
import mixinCharts from "@/components/chart/mixinCharts.js";

import TabellaFatture from "@/views/documenti/TabellaFatture.vue";

export default {
    name: "TabsGrafico",

    props: {
        filters: { type: Object, default: null },
        col1: { type: Number, default: 8 },
        refresh: { type: Number, default: 0 },
    },

    components: {
        DoughnutChart,
        MixedChart,
        TabellaFatture,
    },

    mixins: [mixinCharts],

    data: () => ({
        activeTab: 0,
        datiTot: {},
        optionsTot: {},
        percValue: "0",

        datiMese: {},
        optionsMese: {},
        keym: 0,
        keyt: 0,

        url: "",
        dialog: false,
        filtri: {},
        caricaDati: 100,
    }),


    methods: {
        clienteSel() {
            return this.filters.cdContatto != null && this.filters.cdContatto != '';
        },

        async caricaDatiTot() {
            let res = await get_dati_grafico_tot(this.filters);
            this.datiTot = res.data;
            if (this.filters.cdContatto != null && this.filters.cdContatto != "")
                if (this.datiTot.datasets[0].data[0] != 0)
                    this.percValue = (100 * this.datiTot.datasets[0].data[1] / this.datiTot.datasets[0].data[0]).toFixed(2).toString();
                else
                    this.percValue = "0"

            this.keyt += 1;
        },

        async caricaDatiMese() {
            let res = await get_dati_grafico_mese(this.filters);
            this.datiMese = res.data;
            this.keym += 1;
        },

        handleChartClick(event, elements) {
            if (elements[0] == undefined) return;
            if (event.srcElement.id != "bar-chart") return;

            let cliente = this.filters.cdContatto == null ? "" : this.filters.cdContatto;
            let mese = this.$i2kFunctions.getShortMonths().indexOf(this.datiMese.labels[elements[0]._index].toLowerCase()) + 1;
            this.url = window.location.href + "?cliente=" + cliente + "&anno=" + this.filters.anno + "&grafico=0&mese=" + mese;
            Object.assign(this.filtri, this.filters);

            this.filtri.mese = mese - 1;
            this.filtri.meseStr = this.$i2kFunctions.getMonths()[Number(mese) - 1];
            this.filtri.cdContatto = cliente;
            this.caricaDati += 1;
            this.dialog = true;
            this.caricaDati += 1;
        },

        getFullscreen() {
            return this.$functions.isMobile()
        }
    },

    mounted() {
        this.optionsTot = this.getOptionsDoughnutChart();
        this.optionsMese = this.getOptionsMixedChart();
        this.optionsMese.onClick = this.handleChartClick;
    },

    watch: {
        refresh() {
            this.caricaDatiTot();
            this.caricaDatiMese();
        }
    }
}
</script>