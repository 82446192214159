<template>
    <v-dialog v-model="dialog" persistent width="400" @keydown.esc="annulla()">
        <v-card>
            <v-card-title>
                Esporta excel ore
                <v-spacer />
                <v-btn icon @click="annulla()"> <v-icon>close</v-icon></v-btn>
            </v-card-title>

            <v-card-text>
                <v-row class="ma-0 pa-0">
                    <!-- <v-switch label="Esporta solo ore per busta paga" v-model="soloOrePaga" /> -->
                    <!-- <v-spacer /> -->
                    <v-select :items="destinazioni" v-model="destinazione" label="Destinazione" />
                </v-row>
            </v-card-text>

            <v-card-actions>
                <v-spacer />
                <v-btn @click="annulla()">Annulla</v-btn>
                <v-btn @click="esportaExcel()" color="primary">Esporta</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import * as XLSX from 'xlsx';
import mixinr from "@/views/clienti/mixinRiepilogo.js";

export default {
    name: "DialogEsporta",

    props: {
        events: { type: Array, default: () => null },
        dialog: { type: Boolean, default: false },
        mese: { type: String, default: "" },
        utente: { type: String, default: "" },
    },

    mixins: [mixinr],

    data: () => ({
        destinazioni: [],
        destinazione: "Tutte",
        soloOrePaga: true,
    }),

    methods: {
        annulla() {
            this.$emit("chiudiDialog");
        },

        esportaExcel() {
            this.esportaOrePaga();
            this.annulla();
        },

        createWorkbookHeader() {
            const header = [
                ['Data', 'Giorno', 'Ore', 'Tipo ore'],
            ];

            const cols = [
                { wch: 12 },
                { wch: 15 },
                { wch: 6 },
                { wch: 15 }
            ];

            return { header, cols };
        },

        createWorkbookRow(dateToPrint, weekDay, totalHours, totalDest) {
            const rowData = [dateToPrint, weekDay, totalHours, totalDest];

            rowData[2] = { v: totalHours, t: 's' };
            return rowData;
        },

        calculateTotalHoursAndDest(eventsForDate) {
            let totalHours = "0";
            let totalDest = "";

            if (eventsForDate.length > 0) {
                if (eventsForDate.length > 1) {
                    //totalHours = eventsForDate.reduce((acc, event) => acc + event.ore + (event.minuti ? (event.minuti / 60) : 0) + " + ", "").slice(0, -3);
                    totalHours = eventsForDate
                        .map(event => (Number(event.ore) + (event.minuti ? Number(event.minuti) / 60 : 0)).toFixed(1))
                        .join(" + ");
                    totalDest = eventsForDate.map(event => event.tipoOre).join(" + ");
                } else {
                    totalHours = eventsForDate[0].ore + (eventsForDate[0].minuti ? (eventsForDate[0].minuti / 60) : 0);
                    totalDest = eventsForDate[0].tipoOre;
                }
            }
            return { totalHours, totalDest };
        },

        createWorkbookData(events, destination) {
            const wb = XLSX.utils.book_new();

            const firstEventDate = new Date(events[0].data);
            const month = firstEventDate.getMonth();
            const year = firstEventDate.getFullYear();

            const daysInMonth = new Date(year, month + 1, 0).getDate();
            const monthDays = Array.from({ length: daysInMonth }, (_, i) => i + 1);

            const { header, cols } = this.createWorkbookHeader();
            const ws = XLSX.utils.aoa_to_sheet(header);
            ws['!cols'] = cols;

            monthDays.forEach(day => {
                let date = new Date(year, month, day + 1);
                let formattedDate = date.toISOString().split('T')[0];
                let dateToPrint = this.$i2kFunctions.formatISODate(date.toISOString());
                let weekDay = this.$functions.getWeekDay(formattedDate);
                let eventsForDate = events.filter(event => event.data === formattedDate && (destination == "Tutte" || (event.destinazione == destination && event.tipoOre == "Lavoro")));
                let { totalHours, totalDest } = this.calculateTotalHoursAndDest(eventsForDate);
                let rowData = this.createWorkbookRow(dateToPrint, weekDay, totalHours, totalDest);
                XLSX.utils.sheet_add_aoa(ws, [rowData], { origin: day + 1, raw: false });
            });

            XLSX.utils.book_append_sheet(wb, ws, this.mese);
            return wb;
        },

        exportWorkbook(wb) {
            XLSX.writeFile(wb, 'ore_' + this.mese + "_" + this.utente + '.xlsx');
        },

        esportaOrePaga() {
            if (!this.events || !this.events[0]) {
                this.$toast.alert("Nessun dato disponibile");
                return;
            }

            const wb = this.createWorkbookData(this.events, this.destinazione);
            this.exportWorkbook(wb);
        },
    },

    mounted() {
        this.destinazioni = this.getDestinazioni();
    }
}
</script>

<style scoped>
#excelSheet table td:nth-child(2) {
    text-align: right;
}
</style>