import Functions from "@/utils/functions.js";

export default {

  getToken() {
    return localStorage.getItem('token')
  },

  getRefToken() {
    return localStorage.getItem('ref_token')
  },

  getDataExpiredToken() {
    return localStorage.getItem('exp_data')
  },

  getAuthHeader() {
    return 'Bearer ' + this.getToken()
  },


  checkRemember() {
    return !!localStorage.getItem('ref_token')
  },

  checkAuth() {
    return !!localStorage.getItem('token')
  },


  getExpiration(tokenExpiration) {

    var now = new Date();
    now.setMinutes(now.getMinutes() + Math.ceil((tokenExpiration / 60)))

    return Functions.DateFormat(now);
  },


  clearStorage(keepPreferences) {
    if (keepPreferences) {
      let p = this.getPreferences();
      let docu = this.getDocDefault();
      localStorage.clear();
      localStorage.setPreferences(p);
      localStorage.setDocDefault(docu);
    } else localStorage.clear();
  },


  logout() {

    // Puliza storage
    this.clearStorage(true)

    // Pulisce lo state perchè ricarica la pagina
    window.location = '/login'

  },

  setStorage(data) {

    localStorage.setItem('token', data.access_token);
    localStorage.setItem('exp_data', this.getExpiration(data.expires_in))

    if (data.refresh_token)
      localStorage.setItem('ref_token', data.refresh_token)
  },


  getPreferences() {
    return localStorage.getItem('preferences')
  },

  setPreferences(pr) {
    localStorage.setItem("preferences", JSON.stringify(pr))
  },


  getUsername() {
    return localStorage.getItem('username')
  },

  setUsername(usr) {
    localStorage.setItem("username", usr)
  },

  getRole() {
    return localStorage.getItem('role')
  },

  setRole(role) {
    localStorage.setItem("role", role)
  },


  //pagina predefinita in apertura
  getMenuDefault() {
    return localStorage.getItem("menu_default")
  },

  setMenuDefault(menu) {
    localStorage.setItem("menu_default", JSON.stringify(menu))
  },

  getDocDefault() {
    return localStorage.getItem("docu_default")
  },

  setDocDefault(docu) {
    localStorage.setItem("docu_default", docu)
  },

}