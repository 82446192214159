<template src="./fatture.html"></template>

<script>
import { get_clienti } from "@/api/api.anagrafica.js";

import mixinDocu from "./mixinDocu.js";
import TabellaFatture from "./TabellaFatture.vue";
import DialogGraficoFatturato from "@/components/dialog/documenti/DialogGraficoFatturato";

export default {
  name: "PageFatture",

  components: {
    TabellaFatture, DialogGraficoFatturato
  },

  mixins: [mixinDocu],

  data: () => ({
    filtri: {
      anno: new Date().getFullYear(),
      cdContatto: "",
      raggruppaClienti: false,
      meseStr: null,
      mese: null,
      operatore: [],
      dataInizio: "",
      dataInizioIT: "",
      dataFine: "",
      dataFineIT: "",
    },
    search: "",
    elencoAnni: [],
    elencoClienti: [],
    elencoMesi: [],
    elencoOperatori: [],

    calendarDataDa: false,
    calendarDataA: false,
    calendarDataDaM: false,
    calendarDataAM: false,

    opzioni: [],
    periodoSel: "",

    aggiornaDati: 0,
    altezzaTabella: "69vh",
    isExpanded: false,

    dialogGrafico: false,
  }),

  methods: {
    caricaDati() {
      this.filtri.mese = this.elencoMesi.indexOf(this.filtri.meseStr);
      this.aggiornaDati += 1;
    },

    caricaAnni() {
      for (let i = this.filtri.anno; i >= 2019; i--) {
        this.elencoAnni.push(i);
      }
    },

    toggleExpand() {
      this.isExpanded = !this.isExpanded;
      if (this.isExpanded) this.altezzaTabella = this.$functions.isMobile() ? "53vh" : "60vh";
      else this.altezzaTabella = this.$functions.isMobile() ? "66vh" : "70vh";
    },

    async caricaClienti() {
      let res = await get_clienti();
      this.elencoClienti = res.data;
    },

    refresh() {
      this.caricaDati();
    },

    impostaData() {
      this.applicaData(this.filtri, this.periodoSel);
    },

    pulisciPeriodo() {
      this.periodoSel = "";
      this.filtri.dataInizio = null;
      this.filtri.dataFine = null;
    },

    leggiParametriUrl() {
      let params = new URLSearchParams(window.location.search);

      let anno = params.get("anno") || null;
      if (anno != null) this.filtri.anno = Number(anno);

      let mese = params.get("mese") || null;
      if (mese != null) {
        this.filtri.mese = Number(mese) - 1;
        this.filtri.meseStr = this.elencoMesi[Number(mese) - 1];
      }

      this.filtri.cdContatto = params.get("cliente") || "";
    },

    mostraGrafico() {
      this.dialogGrafico = true;
    },

    chiudiGrafico() {
      this.dialogGrafico = false;
    },

  },

  mounted() {
    this.opzioni = this.getPeriodi();
    this.caricaAnni();
    this.caricaClienti();
    this.leggiParametriUrl();
    this.caricaDati();
  },

  created() {
    this.filtri.mese = this.elencoMesi.indexOf(this.filtri.meseStr);
    this.altezzaTabella = this.$functions.isMobile() ? "66vh" : "70vh";
    this.elencoMesi = this.$i2kFunctions.getMonths();
  },

  watch: {
    "filtri.dataInizio": function (newVal, oldVal) {
      if (
        newVal != null &&
        newVal != "" &&
        this.filtri.dataFine != null &&
        this.filtri.dataFine != "" &&
        newVal > this.filtri.dataFine
      ) {
        this.filtri.dataInizio = oldVal;
        this.$toast.alert("Data non valida");
      }
      this.filtri.dataInizioIT = this.$i2kFunctions.formatISODate(
        this.filtri.dataInizio
      );
    },

    "filtri.dataFine": function (newVal, oldVal) {
      if (
        newVal != null &&
        newVal != "" &&
        this.filtri.dataInizio != null &&
        this.filtri.dataInizio != "" &&
        newVal < this.filtri.dataInizio
      ) {
        this.filtri.dataFine = oldVal;
        this.$toast.alert("Data non valida");
      }
      this.filtri.dataFineIT = this.$i2kFunctions.formatISODate(this.filtri.dataFine);
    },
  }
};
</script>

<style scoped>
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0s ease;
}

.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateY(-10px);
  opacity: 0;
}
</style>