<template>
    <v-container fluid fill-height class="ma-0 pa-0">
        <v-col cols="12" class="ma-0 pa-0">
            <v-card class="ma-0 pa-0 desktop">
                <v-col class="ma-0 pa-0">
                    <v-row class="ma-0 pa-0">
                        <v-col cols="3">Totale ore cliente: </v-col>
                        <v-col cols="1" class="totale">{{ totale.totaleOreCliente }}</v-col>

                        <v-col cols="3">Totale ore ufficio: </v-col>
                        <v-col cols="1" class="totale">{{ totale.totaleOreUfficio }}</v-col>
                    </v-row>
                    <v-row class="ma-0 pa-0">
                        <v-col cols="2">Importo totale: </v-col>
                        <v-col cols="2" class="totale">€ {{ totale.totaleImportoStr }}</v-col>
                        <v-spacer />
                    </v-row>
                </v-col>
            </v-card>

            <v-card class="ma-0 pa-0 mobile">
                <v-col class="ma-0 pa-0">
                    <v-row class="ma-0 pa-0">
                        <v-col cols="4" class="pl-1 pr-0 pt-1 pb-1">Totale ore cliente: </v-col>
                        <v-col cols="2" class="totale">{{ totale.totaleOreCliente }}</v-col>

                        <v-col cols="4" class="pl-4 pr-0 pt-1 pb-1">Totale ore ufficio: </v-col>
                        <v-col cols="2" class="totale">{{ totale.totaleOreUfficio }}</v-col>
                    </v-row>
                    <v-divider class="ma-0 pa-0" />
                    <v-row class="ma-0 pa-0">
                        <v-col cols="6" class="pl-1 pr-0 pt-1 pb-1">Importo totale: </v-col>
                        <v-col cols="6" class="totale">€ {{ totale.totaleImportoStr }}</v-col>
                        <v-spacer />
                    </v-row>
                </v-col>
            </v-card>

            <v-data-table :items="elencoDati" :headers="headersDati" :items-per-page="999"
                no-data-text="Nessun dato disponibile" hide-default-footer :mobile-breakpoint="0" style="width: 100%;"
                :height="altezzaTabella" fixed-header class="mt-1">
                <template v-slot:item="{ item }">
                    <tr :class="raggruppaClienti ? 'isClickable' : ''"
                        @click="raggruppaClienti ? $emit('dettaglioCliente', item) : ''">
                        <td v-if="!raggruppaClienti"> {{ $functions.getWeekDay(item.dataFormat).substring(0, 3) + " " +
                            item.data }} </td>
                        <td class="text-xs-right"> {{ item.operatore }} </td>
                        <td class="text-xs-right"> {{ item.cliente }} </td>
                        <td class="text-xs-right"> {{ item.destinazione }}</td>
                        <td class="text-xs-right"> {{ item.totaleOre }}
                        </td>
                        <td class="text-xs-right" v-if="!raggruppaClienti"> {{ item.tipoImporto }} </td>
                        <td class="text-xs-right" v-if="!raggruppaClienti"> {{ item.importo }} </td>
                        <td class="text-xs-right"> {{ item.importoTotale }} </td>
                    </tr>
                </template>
            </v-data-table>
        </v-col>
    </v-container>
</template>

<script>
import mixinr from "./mixinRiepilogo.js";
export default {
    name: "TabellaRiepilogo",
    mixins: [mixinr],

    props: {
        raggruppaClienti: { type: Boolean, default: false },
        elencoDati: { type: Array, default: () => [] },
        totale: { type: Object, default: () => { } },
        altezzaTabella: { type: String, default: "auto" },
    },

    data: () => ({
        headersDati: [],
    }),


    methods: {
    },

    mounted() {
        this.headersDati = this.getHeadersTabella();
    },

    watch: {
        raggruppaClienti() {
            if (this.raggruppaClienti) {
                this.headersDati = this.getHeadersTabellaRaggruppa();
            } else {
                this.headersDati = this.getHeadersTabella();
            }
        },
    }
}
</script>