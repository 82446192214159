<template src="./errors.html"></template>

<script>
import DialogError from "@/components/dialog/DialogError.vue";

import { get_errors } from "@/api/api.tools.js";

export default {
  name: "ErrorPage",

  components: { DialogError },

  data: () => ({
    date: new Date().toISOString().substr(0, 7),
    menu: false,

    page: 1,
    pageCount: 0,
    itemsPerPage: 50,
    search: "",

    loadingTable: false,
    headerProps: {
      sortByText: "Ordina per",
    },

    headerTabella: [
      { text: "UserID", value: "userID" },
      { text: "Date", value: "dateOccur" },
      { text: "Error", value: "error" },
      { text: "Method", value: "modelFunction" },
      { text: "Stato", value: "debug" },
      { text: "Info", value: "" },
    ],

    errorList: [],

    dialog: false,
    info: {},
  }),

  methods: {
    /* async caricaDati() {
      let res = await get_errors();
      this.errorList = res.data;
    }, */

    async caricaDati() {
      this.loadingTable = true;
      let par = {
        DateStart: this.date + "-01",
        DateEnd: this.date + "-01",
      };

      let res = await get_errors(par);
      this.errorList = res.data;

      this.loadingTable = false;
    },

    Refresh() {
      this.dialog = false;
      this.eccezioni = [];
      this.search = "";
      this.caricaDati();
    },

    apriDialog(item) {
      this.info = item;
      this.dialog = true;
    },

    chiudiDialog() {
      this.info = {};
      this.dialog = false;
    },

    parseData(data) {
      if (data.length == 0) return "";

      const [month, day, year] = data.split("/");
      return `${day}/${month}/${year}`;
    },
  },

  mounted() {
    this.caricaDati();
  },
};
</script>